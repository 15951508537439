var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "mb-2"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Detail",
      "active": ""
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "lg": "9",
      "md": "10",
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }), _c('b-card-actions', {
    staticClass: "mb-2",
    attrs: {
      "title": "Detail Peminjaman",
      "action-collapse": ""
    }
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('td', [_vm._v("Peminjam")]), _c('th', [_vm._v(_vm._s(_vm.peminjaman.peminjam ? _vm.peminjaman.peminjam.nama_lengkap : ''))])]), _c('tr', [_c('td', [_vm._v("Nama Aset")]), _c('th', [_vm._v(_vm._s(_vm.peminjaman.asset ? _vm.peminjaman.asset.nama : ''))])]), _c('tr', [_c('td', [_vm._v("Jumlah Yang Dipinjam")]), _c('th', [_vm._v(_vm._s(_vm.peminjaman.jumlah))])]), _c('tr', [_c('td', [_vm._v("Satuan Aset")]), _c('th', [_vm._v(_vm._s(_vm.peminjaman.asset && _vm.peminjaman.asset.satuan ? _vm.peminjaman.asset.satuan.satuan : ''))])]), _c('tr', [_c('td', [_vm._v("Lokasi")]), _c('th', [_vm._v(_vm._s(_vm.peminjaman.lokasi))])])]), _c('br'), _c('br'), _c('br'), parseInt(_vm.peminjaman.jumlah) > 0 ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.doPengembalian($event);
      }
    }
  }, [_vm._v(" Kembalikan Peminjaman ")])], 1) : _vm._e()]), _c('b-card-actions', {
    attrs: {
      "collapsed": "",
      "title": "Lainnya",
      "action-collapse": ""
    }
  }, [_vm.history_peminjamans.length > 0 ? _c('a', {
    staticClass: "text-danger mb-2",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.confirmCancel($event);
      }
    }
  }, [_c('strong', [_vm._v("Batalkan Peminjaman?")])]) : _vm._e()])], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Riwayat Peminjaman"
    }
  }, [_c('table-riwayat')], 1)], 1), _c('pengembalian-modal', {
    on: {
      "submitPengembalian": _vm.submitPengembalian
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }