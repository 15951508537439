<template>
  <b-modal
    id="pengembalian-modal"
    no-close-on-backdrop
    no-close-on-esc
    title="Pilih Tempat Penyimpanan"
    size="md"
  >
    <b-table
      class="w-full"
      :fields="fields"
      :items="penyimpanans"
    >
      <template #cell(radio)="{item}">
        <b-form-radio
          v-model="selectedPenyimpanan"
          name="penyimpanan"
          :value="item"
        />
      </template>
      <template #cell(blok)="{item}">
        <span v-if="item.blok">{{ item.blok.blok }}</span>
        <i
          v-else
          class="text-danger"
        >Blok tidak ditemukan</i>
      </template>
      <template #cell(stok)="{item}">
        <b-form-input
          v-model="item.jumlah"
          type="number"
        />
      </template>
    </b-table>

    <template #modal-footer>
      <b-button
        variant="primary"
        @click.prevent="submit"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
import {
  BModal, BRow, BCol, BTable, BFormRadio, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BRow, BCol, BTable, BFormRadio, BFormInput, BButton,
  },
  data: () => ({
    jumlah: 0,
    selectedPenyimpanan: null,
    fields: [
      { key: 'radio', label: '#' },
      { key: 'blok', label: 'Blok' },
      { key: 'stok', label: 'Stok' },
    ],
  }),
  computed: {
    ...mapState('asset', ['penyimpanans']),
  },
  methods: {
    submit() {
      if (!this.selectedPenyimpanan) {
        this.displayError({
          message: 'Harap pilih tempat penyimpanan!',
        })
        return false
      }

      if (this.selectedPenyimpanan.jumlah < 1) {
        this.displayError({
          message: 'Jumlah pengembalian minimal 1!',
        })
        return false
      }

      const payload = {
        id_penyimpanan: this.selectedPenyimpanan.id,
        id_peminjaman: null,
        jumlah: parseInt(this.selectedPenyimpanan.jumlah),
      }
      this.$emit('submitPengembalian', payload)
    },
  },
}
</script>
